// api域名配置
const baseUrl = {
  prod: 'https://fc-mp-1d6be9d7-0c65-481f-ab3c-a6af0c767aac.next.bspapp.com/http/router',
  dev: 'https://fc-mp-1d6be9d7-0c65-481f-ab3c-a6af0c767aac.next.bspapp.com/http/router'
}
const DOMAIN = baseUrl.prod

export {
  DOMAIN
}
