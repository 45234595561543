<template>
  <div class="application">
    <page-header :roll="roll" />
    <div class="container">
      <div class="bg">
        <img class="hidden-sm-and-down" :src="background.application || ''" alt="">
        <el-image class="hidden-md-and-up" :src="background.application || ''" fit="cover" style="width: 100%; height: 200px;" />
        <div class="title">{{ application.name }}</div>
      </div>
      <div class="content">
        <div class="left hidden-sm-and-down">
          <el-menu :default-active="active">
            <el-submenu
              v-for="item in classification"
              :key="item._id"
              :index="item._id"
            >
              <template slot="title">
                <span>{{ item.name }}</span>
              </template>
              <el-menu-item
                v-for="sub in item.children"
                :key="sub._id"
                :index="sub._id"
                @click="switchMenu(item._id, sub._id)"
              >{{ sub.name }}</el-menu-item>
            </el-submenu>
          </el-menu>
        </div>
        <div class="right">
          <div v-if="application.name" class="title">
            <img src="@/assets/icon.png" alt="">
            <span>{{ application.name }}</span>
          </div>
          <div style="white-space: pre-wrap;" v-html="application.content"></div>
        </div>
      </div>
    </div>
    <page-footer />
  </div>
</template>

<script>
import PageHeader from "@/components/header.vue";
import PageFooter from "@/components/footer.vue";

export default {
  name: 'application',
  props: {
    background: {}
  },
  components: {
    PageHeader,
    PageFooter,
  },
  data() {
    return {
      lang: window.localStorage.getItem('lang'),
      active: '',
      roll: false,
      classification: [],
      application: {
        name: '',
        content: '',
      }
    }
  },
  watch: {
    '$route'() {
      let activeClassification = this.classification.find(el => el._id == this.$route.params.id)
      if(activeClassification && activeClassification.children.length > 0) {
        if(this.$route.query.id) {
          this.active = this.$route.query.id || activeClassification.children[0]._id
          this.getApplication()
        } else {
          this.$router.push('/application/detail/' + this.$route.params.id + '?id=' + activeClassification.children[0]._id)
        }
      }
    }
  },
  created() {
    this.getClassification()
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      this.roll = scrollTop > 70 ? true : false
    },
    switchMenu(classification, application) {
      if(this.active == application) return
      this.$router.push('/application/detail/' + classification + '?id=' + application)
    },
    getClassification() {
      this.$api.get('/client/application/pub/getMenu', {}, res => {
        this.classification = res.data
        let activeClassification = this.classification.find(el => el._id == this.$route.params.id)
        if(activeClassification && activeClassification.children.length > 0) {
          if(this.$route.query.id) {
            this.active = this.$route.query.id || activeClassification.children[0]._id
            this.getApplication()
          } else {
            this.$router.push('/application/detail/' + this.$route.params.id + '?id=' + activeClassification.children[0]._id)
          }
        }
      }, err => {
        console.log(err)
      })
    },
    getApplication() {
      this.$api.get('/client/application/pub/getApplicationById', {
        id: this.active
      }, res => {
        let data = res.data[0]
        this.application.name = data.name || ''
        this.application.content = data.content || ''

        this.$api.get('/client/access-statistics/pub/index', {
          code: '2-application',
          name: '应用',
          pageCode: 'detail-' + this.$route.query.id,
          title: this.application.name,
          url: this.$route.fullPath
        })
      }, err => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>
.application .bg {
  width: 100%;
  font-size: 0;
  position: relative;
}

.application .bg img {
  width: 100%;
}
.application .bg .title {
  font-size: 30px;
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 20px;
  background-color: rgb(1, 51, 120, .5);
}

.content {
  width: 100%;
  display: flex;
}

.content .left {
  flex-shrink: 0;
  width: 250px;
  background-color: #FAFBFC;
}

.content .left >>> .el-menu {
  background-color: #FAFBFC;
  height: 100%;
}

.el-submenu .el-menu-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.content .right {
  flex-grow: 1;
  padding: 15px 20px;
}

.content .right > .title {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.content .right > .title img {
  height: 8px;
  margin-right: 10px;
}

.content .right > .title span {
  font-size: 18px;
}
</style>