<template>
  <div class="product">
    <page-header :roll="roll" />
    <div class="container">
      <div class="bg">
        <img class="hidden-sm-and-down" :src="background.product || ''" alt="">
        <el-image class="hidden-md-and-up" :src="background.product || ''" fit="cover" style="width: 100%; height: 200px;" />
        <div class="title">所有产品</div>
      </div>
      <div class="content">
        <div class="right">
          <div class="filter">
            <div class="title">筛选条件</div>
            <div class="list">
              <el-checkbox
                v-for="item in attribute"
                :key="item._id"
                v-model="item.checked"
              ><span v-html="item.name" class="hb"></span></el-checkbox>
            </div>
          </div>
          <el-table
            :data="product"
            :header-cell-style="{'background-color': '#013378', 'color': '#fff', 'font-weight': '400'}"
            :span-method="tableSpanMethod"
            border
            style="width: 100%;">
            <el-table-column
              prop="classification"
              label="分类"
              width="120">
              <template slot-scope="scope">
                <span v-html="scope.row.classification" class="ttt"></span>
              </template>
            </el-table-column>
            <!-- <el-table-column
              prop="name"
              label="产品"
              width="120">
            </el-table-column> -->
            <template v-for="item in attribute">
              <el-table-column
                v-if="item.checked"
                :key="item._id"
                :prop="item._id"
                min-width="200"
                sortable
              >
                <template slot="header">
                  <span v-html="item.name" class="ttt"></span>
                </template>
              </el-table-column>
            </template>
            <el-table-column
              fixed="right"
              label="附件"
              min-width="100"
            >
              <template slot-scope="scope">
                <el-button v-if="scope.row.file" type="text" @click="downloadFile(scope.row.file, scope.row._index)">下载附件</el-button>
                <template v-else>{{ scope.row.file_tip }}</template>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <page-footer />
  </div>
</template>

<script>
import PageHeader from "@/components/header.vue";
import PageFooter from "@/components/footer.vue";

export default {
  name: 'Product',
  props: {
    background: {}
  },
  components: {
    PageHeader,
    PageFooter,
  },
  data() {
    return {
      lang: window.localStorage.getItem('lang'),
      roll: false,
      attribute: [],
      product: []
    }
  },
  created() {
    this.getAttribute()
    this.getClassification()
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    tableSpanMethod({ row, columnIndex }) {
      if (columnIndex === 0) {
        return {
          rowspan: row.classificationSpan,
          colspan: 1
        }
      }
      // if (columnIndex === 1) {
      //   return {
      //     rowspan: row.nameSpan,
      //     colspan: 1
      //   }
      // }
      return {
        rowspan: 1,
        colspan: 1
      }
    },
    downloadFile(file, index) {
      this.$api.get('/client/product/pub/setStatistics', {
        _id: this.$route.query.id,
        _index: index
      })
      window.open(file)
    },
    handleScroll() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      this.roll = scrollTop > 70 ? true : false
    },
    getClassification() {
      this.product = []
      this.$api.get('/client/product/pub/getMenu', {}, res => {
        let data = res.data
        data.forEach(el => {
          let classificationSpan = 0
          el.children.forEach(subSum => {
            if (!subSum.list) return
            classificationSpan += subSum.list.length
          })
          el.children.forEach((sub, i) => {
            if (!sub.list) return
            let nameSpan = sub.list.length
            sub.list.forEach((list, j) => {
              this.product.push({
                ...list,
                classification: el.name,
                name: sub.name,
                classificationSpan: i == 0 && j == 0 ? classificationSpan : 0,
                nameSpan: j == 0 ? nameSpan : 0
              })
            })
          })
        })

        this.$api.get('/client/access-statistics/pub/index', {
          code: '3-product',
          name: '产品',
          pageCode: 'list',
          title: '产品列表',
          url: this.$route.fullPath
        })
      }, err => {
        console.log(err)
      })
    },
    getAttribute() {
      this.$api.get('/client/product/pub/getAttribute', {}, res => {
        let data = res.data || []
        data.forEach(el => {
          el['checked'] = true
        })
        this.attribute = data
      }, err => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>
.product >>> .ttt {
  font-size: 16px;
}
.product >>> .ttt span {
  font-size: 12px;
}
.product .bg {
  width: 100%;
  font-size: 0;
  position: relative;
}

.product .bg img {
  width: 100%;
}
.product .bg .title {
  font-size: 30px;
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 20px;
  background-color: rgb(1, 51, 120, .5);
}

.content {
  width: 100%;
  display: flex;
}

.content .left {
  flex-shrink: 0;
  width: 250px;
  background-color: #FAFBFC;
}

.content .left >>> .el-menu {
  background-color: #FAFBFC;
  height: 100%;
}

.el-submenu .el-menu-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.content .right {
  flex-grow: 1;
  overflow: auto;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
}

.content .right > .title {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.content .right > .title img {
  height: 8px;
  margin-right: 10px;
}

.content .right > .title span {
  font-size: 18px;
}

.content .right .filter {
background-color: #FAFBFC;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 20px;
  border: solid 1px #e6e6e6;
}

.content .right .filter .title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.content >>> .el-table td.el-table__cell div {
  font-weight: 100;
  opacity: .8;
  font-size: 14px;
  color: #333;
}
</style>