<template>
  <div class="contact">
    <page-header :roll="roll" />
    <div class="container">
      <div class="bg">
        <img class="hidden-sm-and-down" :src="background.contact || ''" alt="">
        <el-image class="hidden-md-and-up" :src="background.contact || ''" fit="cover" style="width: 100%; height: 200px;" />
        <div class="title">联系我们</div>
      </div>
      <div class="content">
        <div class="btn-group">
          <div
            v-for="(item, i) in contact"
            :key="item._id"
            :class="{ active: active == i }"
            class="btn"
            @click="active = i; setMap();"
          >
            {{ item.city }}
          </div>
        </div>
        <el-row v-if="contact.length > 0" class="card-group" :gutter="20">
          <el-col :md="8" :sm="24">
            <el-card shadow="hover">
              <i class="el-icon-location-outline" style="color: #f56c6c"></i>
              <div class="title">公司地址</div>
              <div class="text">{{ contact[active].address }}</div>
            </el-card>
          </el-col>
          <el-col :md="8" :sm="24">
            <el-card shadow="hover">
              <i class="el-icon-message" style="color: #409eff"></i>
              <div class="title">电子邮箱</div>
              <div class="text">{{ contact[active].email }}</div>
            </el-card>
          </el-col>
          <el-col :md="8" :sm="24">
            <el-card shadow="hover">
              <i class="el-icon-phone-outline" style="color: #67c23a"></i>
              <div class="title">联系电话</div>
              <div class="text">{{ contact[active].phone }}</div>
            </el-card>
          </el-col>
        </el-row>
        <div id="map"></div>
      </div>
    </div>
    <page-footer />
  </div>
</template>

<script>
import PageHeader from "@/components/header.vue";
import PageFooter from "@/components/footer.vue";

export default {
  name: "contact",
  props: {
    background: {}
  },
  components: {
    PageHeader,
    PageFooter,
  },
  data() {
    return {
      lang: window.localStorage.getItem("lang"),
      roll: false,
      active: 0,
      contact: [],
      map: null
    };
  },
  created() {
    this.getContact();
    this.$api.get('/client/access-statistics/pub/index', {
      code: '5-about',
      name: '关于GaNext',
      pageCode: 'contact',
      title: '联系我们',
      url: this.$route.fullPath
    })
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.roll = scrollTop > 70 ? true : false;
    },
    getContact() {
      this.$api.get("/client/contact/pub/getList", {}, (res) => {
        this.contact = res.data;
        if(this.contact.length > 0) this.initMap(this.setMap)
      }, (err) => {
        console.log(err);
      });
    },
    initMap(cb) {
      this.map = new window.BMapGL.Map('map');
      this.map.enableScrollWheelZoom(true);
      let scaleCtrl = new window.BMapGL.ScaleControl();  // 添加比例尺控件
      this.map.addControl(scaleCtrl);
      let zoomCtrl = new window.BMapGL.ZoomControl();  // 添加缩放控件
      this.map.addControl(zoomCtrl);
      cb && cb()
    },
    setMap() {
      let that = this
      that.map.clearOverlays();
      let myGeo = new window.BMapGL.Geocoder();
      // 将地址解析结果显示在地图上，并调整地图视野
      myGeo.getPoint(that.contact[that.active].address, function (point) {
        if (point) {
          that.map.centerAndZoom(point, 16);
          that.map.addOverlay(
            new window.BMapGL.Marker(point, { title: that.contact[that.active].address })
          );
        } else {
          alert("您选择的地址没有解析到结果！");
        }
      });
    },
  },
};
</script>

<style scoped>
.contact .bg {
  width: 100%;
  font-size: 0;
  position: relative;
}

.contact .bg img {
  width: 100%;
}
.contact .bg .title {
  font-size: 30px;
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 20px;
  background-color: rgb(1, 51, 120, 0.5);
}

.content {
  width: 90%;
  margin: 0 auto;
  padding: 20px 0;
}

.content .btn-group {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 50px 0;
}

.content .btn-group .btn {
  background-color: #f2f5f7;
  color: #013378;
  border-radius: 4px;
  padding: 5px 15px;
  font-size: 16px;
  cursor: pointer;
}

.content .btn-group .btn:not(:last-child) {
  margin-right: 10px;
}

.content .btn-group .btn:hover {
  background-color: #013378;
  color: #fff;
}

.content .btn-group .btn.active {
  background-color: #013378;
  color: #fff;
  box-shadow: 0px 2px 6px 0px rgb(1, 51, 120, 0.3);
}

.content .card-group {
  margin-bottom: 20px;
}

.content .card-group .el-card {
  border-radius: 0;
  margin-bottom: 20px;
}

.content .card-group >>> .el-card__body {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.content .card-group i {
  font-size: 36px;
  margin-bottom: 10px;
}

.content .card-group .title {
  font-size: 23px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #013378;
}

.content .card-group .text {
  font-size: 16px;
  color: #606060;
}

.content #map {
  width: 100%;
  height: 500px;
  margin-bottom: 40px;
}
</style>