<template>
  <div class="news">
    <page-header :roll="roll" />
    <div class="container">
      <div class="bg">
        <img class="hidden-sm-and-down" :src="background.news || ''" alt="">
        <el-image class="hidden-md-and-up" :src="background.news || ''" fit="cover" style="width: 100%; height: 200px;" />
        <div class="text">
          <div class="title">{{ news.name }}</div>
          <div class="label">{{ new Date(news.releaseDate).Format('yyyy-MM-dd') }}</div>
        </div>
      </div>
      <div class="content">
        <div style="white-space: pre-wrap;" v-html="news.content"></div>
      </div>
    </div>
    <page-footer />
  </div>
</template>

<script>
import PageHeader from "@/components/header.vue";
import PageFooter from "@/components/footer.vue";

export default {
  name: 'news',
  props: {
    background: {}
  },
  components: {
    PageHeader,
    PageFooter,
  },
  data() {
    return {
      lang: window.localStorage.getItem('lang'),
      roll: false,
      news: {
        name: '',
        releaseDate: '',
        content: ''
      }
    }
  },
  created() {
    this.getNews()
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      this.roll = scrollTop > 70 ? true : false
    },
    getNews() {
      this.$api.get('/client/news/pub/getNewsById', {
        id: this.$route.params.id
      }, res => {
        let data = res.data[0]
        this.news.name = data.name
        this.news.releaseDate = data.releaseDate
        this.news.content = data.content
        this.$api.get('/client/access-statistics/pub/index', {
          code: '6-news',
          name: '最新发布',
          pageCode: 'detail-' + this.$route.params.id,
          title: this.news.name,
          url: this.$route.fullPath
        })
      }, err => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>
.news .bg {
  width: 100%;
  font-size: 0;
  position: relative;
}

.news .bg img {
  width: 100%;
}
.news .bg .text {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 20px;
  background-color: rgb(1, 51, 120, .5);
}
.news .bg .title {
  font-size: 24px;
  color: #fff;
}
.news .bg .label {
  margin-top: 5px;
  font-size: 14px;
  color: #e7e7e7;
}

.content {
  width: 90%;
  margin: 0 auto;
  padding: 30px 0;
}
</style>