import axios from 'axios'
// import qs from 'qs'
import { DOMAIN } from '@/util/config'

// 网络请求
const $api = {}
/*
 * GET请求
 * @param url
 * @param data
 * @param success
 * @param error
 */
$api.get = (url, data, success, error) => {
  if(!success) success = () => {}
  if(!error) error = () => {}

  axios.get(`${DOMAIN}${url}`, {
    params: data
  }).then((response) => {
    const result = response.data

    if(!result) return error('网络繁忙，请稍后重试')
    if(result.code != 200) return error(result.msg)

    success(result)
  }).catch((err) => {
    console.log(err)
    error('网络繁忙，请稍后重试')
  })
}
/*
 * POST请求
 * @param url
 * @param data
 * @param success
 * @param error
 */
$api.post = (url, data, success, error) => {
  if(!success) success = () => {}
  if(!error) error = () => {}

  axios.post(`${DOMAIN}${url}`, data)
  .then((response) => {
    const result = response.data

    if(!result) return error('网络繁忙，请稍后重试')
    if(result.code != 200) return error(result.msg)

    success(result)
  }).catch((err) => {
    console.log(err)
    error('网络繁忙，请稍后重试')
  })
}
/*
 * 并发请求
 * @param arr
 * @param complete
 */
$api.all = (arr, complete) => {
  if(!complete) complete = () => {}

  let funArr = []
  for(let i in arr)
    funArr.push(axios.get(`${DOMAIN}${arr[i].url}`, { params: arr[i].data }))
  axios.all(funArr).then(axios.spread(complete))
}

/*
 * socket接口请求
 * @param url
 * @param type
 * @param data
 * @param success
 * @param error
 */
$api.socket = (url, type, data, success, error) => {
  if (!success) success = () => {}
  if (!error) error = () => {}

  // 防止浏览器读缓存
  if (type === 'get') {
    data['_t'] = new Date().getTime()
  }

  axios[type || 'get'](`/socket${url}`, type === 'get' ? {params: data} : data)
  .then((response) => {
    const result = response.data

    if (!result) return error('网络繁忙，请稍后重试')
    if (!result.success) return error(result.msg)

    success(result)
  }).catch((err) => {
    console.log(err)
    error('网络繁忙，请稍后重试')
  })
}

export {
  $api
}
