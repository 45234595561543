<template>
  <div>
    <div class="footer hidden-sm-and-down">
      <div class="top">
        <div class="info">
          <div style="color: #fff;font-size: 24px;">CONTACT</div>
          <div style="color: #fff;font-weight: 600;font-size: 32px;">INFORMATION</div>
          <div class="list">
            <div class="item">
              <i class="el-icon-message"></i>
              <span>邮箱：{{ info.footerEmail }}</span>
            </div>
            <div class="item">
              <i class="el-icon-phone-outline"></i>
              <span>电话：{{ info.footerPhone }}</span>
            </div>
            <div class="item">
              <i class="el-icon-location-outline"></i>
              <span>珠海地址：{{ info.footerAddressZhuhai }}</span>
            </div>
            <div class="item">
              <i class="el-icon-location-outline"></i>
              <span>深圳地址：{{ info.footerAddressShenzhen }}</span>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="menu">
            <div class="item">
              <div class="title">{{ $lang.application[lang] }}</div>
              <div class="sub-list">
                <div class="sub-item" v-for="item in applicationClassification" :key="item._id" @click="openPage('/application/classification?id='+item._id)">
                  {{ item.name }}
                </div>
              </div>
            </div>
            <div class="item" @click="openPage('/product')">
              <div class="title">{{ $lang.product[lang] }}</div>
            </div>
            <div class="item">
              <div class="title">{{ $lang.technology[lang] }}</div>
              <div class="sub-list">
                <div class="sub-item" v-for="item in technologyClassification" :key="item._id" @click="openPage('/technology/classification?id='+item._id)">
                  {{ item.name }}
                </div>
              </div>
            </div>
            <div class="item">
              <div class="title">{{ $lang.about[lang] }}</div>
              <div class="sub-list">
                <div class="sub-item" @click="openPage('/about/company-introduce')">公司介绍</div>
                <div class="sub-item" @click="openPage('/about/recruit')">加入我们</div>
                <div class="sub-item" @click="openPage('/about/distributor')">分销商</div>
                <div class="sub-item" @click="openPage('/about/investor-relatioin')">投资者关系</div>
                <div class="sub-item" @click="openPage('/about/contact')">联系我们</div>
              </div>
            </div>
            <div class="item">
              <div class="title">{{ $lang.news[lang] }}</div>
              <div class="sub-list">
                <div class="sub-item" v-for="item in newsClassification" :key="item._id" @click="openPage('/news/classification?id='+item._id)">
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
          <img src="@/assets/qrcode.jpg" />
        </div>
      </div>
      <div class="bottom">
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44049202000320" target="_blank">
          <img src="@/assets/police.png" style="height: 16px; margin-right: 3px;vertical-align: middle;" />
          <span style="vertical-align: middle;">粤公网安备44049202000320号</span>
        </a>
        <a href="https://beian.miit.gov.cn/" target="_break">粤ICP备2022066856号-1</a>
        <span>Copyright © 2022.珠海镓未来科技有限公司 All rights reserved.</span>
      </div>
    </div>
    <div class="footer mobile hidden-md-and-up">
      <div class="top">
        <div class="info">
          <div style="color: #fff;font-size: 24px;">CONTACT</div>
          <div style="color: #fff;font-weight: 600;font-size: 32px;">INFORMATION</div>
          <div class="list">
            <div class="item">
              <i class="el-icon-message"></i>
              <span>邮箱：{{ info.footerEmail }}</span>
            </div>
            <div class="item">
              <i class="el-icon-phone-outline"></i>
              <span>电话：{{ info.footerPhone }}</span>
            </div>
            <div class="item">
              <i class="el-icon-location-outline"></i>
              <span>珠海地址：{{ info.footerAddressZhuhai }}</span>
            </div>
            <div class="item">
              <i class="el-icon-location-outline"></i>
              <span>深圳地址：{{ info.footerAddressShenzhen }}</span>
            </div>
          </div>
        </div>
        <div class="right">
          <img src="@/assets/qrcode.jpg" />
        </div>
      </div>
      <div class="bottom mobile-bottom">
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44049202000320" target="_blank">
          <img src="@/assets/police.png" style="height: 16px; margin-right: 3px;vertical-align: middle;" />
          <span style="vertical-align: middle;">粤公网安备44049202000320号</span>
        </a>
        <a href="https://beian.miit.gov.cn/" target="_break">粤ICP备2022066856号-1</a>
        <span>Copyright © 2022.珠海镓未来科技有限公司 All rights reserved.</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lang: window.localStorage.getItem('lang'),
      applicationClassification: [],
      technologyClassification: [],
      newsClassification: [],
      info: {}
    }
  },
  created() {
    this.getApplicationClassification()
    this.getTechnologyClassification()
    this.getNewsClassification()
    this.getInfo()
  },
  methods: {
    openPage(str) {
      this.$router.push(str)
    },
    getApplicationClassification() {
      this.$api.get('/client/application/pub/getClassification', {}, res => {
        this.applicationClassification = res.data
      }, err => {
        console.log(err)
      })
    },
    getTechnologyClassification() {
      this.$api.get('/client/technology/pub/getClassification', {}, res => {
        this.technologyClassification = res.data
      }, err => {
        console.log(err)
      })
    },
    getNewsClassification() {
      this.$api.get('/client/news/pub/getClassification', {}, res => {
        this.newsClassification = res.data
      }, err => {
        console.log(err)
      })
    },
    getInfo() {
      this.$api.get('/client/home/pub/getInfo', {}, res => {
        this.info = res.data[0]
      }, err => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>
.footer {
  width: 100%;
  padding: 100px 100px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url(../assets/footer.png) no-repeat top left;
  background-size: 100% 100%;
}

.footer .top {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.footer .top .info {
  width: calc(36% - 15px);
  margin-right: 15px;
}
.footer .info .list {
  margin-top: 30px;
}
.footer .info .list .item {
  display: flex;
  align-items: center;
}
.footer .info .list .item i {
  font-size: 16px;
  color: #fff;
  margin-right: 15px;
}
.footer .info .list .item span {
  font-size: 14px;
  font-weight: 100;
  color: #fff;
  opacity: .8;
}
.footer .info .list .item:not(:last-child) {
  margin-bottom: 15px;
}

.footer .top .right {
  width: calc(64% - 15px);
  margin-left: 15px;
  display: flex;
  align-items: center;
}

.footer .right img {
  width: 130px;
}

.footer .right .menu {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  width: calc(100% - 130px);
}

.footer .right .menu .item {
  width: calc(20% - 10px);
  margin: 0 5px;
}

.footer .right .menu .title {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 10px;
  cursor: pointer;
}

.footer .right .menu .sub-item {
  font-size: 14px;
  color: #fff;
  margin-bottom: 8px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.5;
  font-weight: 100;
  opacity: .8;
}

.footer .right .menu .sub-item:hover {
  color: #fff;
}

.footer .bottom {
  display: flex;
  align-items: center;
  margin-top: 70px;
  font-size: 14px;
  color: #fff;
  font-weight: 100;
  opacity: .8;
}

.footer .mobile-bottom {
  flex-direction: column;
  text-align: center;
}

.footer .bottom a {
  color: inherit !important;
  margin-right: 15px;
}

.footer .mobile-bottom a {
  margin-right: 0;
  margin-bottom: 10px;
}

.footer .bottom a:hover {
  color: #fff !important;
}

.footer.mobile {
  padding: 20px;
}
.footer.mobile .top {
  flex-direction: column;
  align-items: flex-start;
}
.footer.mobile .top .info {
  width: 100%;
  margin-right: 0;
  margin-bottom: 15px;
}
.footer.mobile .info .list {
  margin-top: 12px;
}
.footer.mobile .info .list .item:not(:last-child) {
  margin-bottom: 8px;
}
.footer.mobile .top .right {
  width: 100%;
  margin-left: 0;
  justify-content: center;
}
.footer.mobile .mobile-bottom {
  margin-top: 15px;
}
.footer.mobile .mobile-bottom a {
  margin-bottom: 5px;
}
</style>