<template>
  <div class="recruit">
    <page-header :roll="roll" />
    <div class="container">
      <div class="bg">
        <img class="hidden-sm-and-down" :src="background.recruit || ''" alt="">
        <el-image class="hidden-md-and-up" :src="background.recruit || ''" fit="cover"
          style="width: 100%; height: 200px;" />
        <div class="title">加入我们</div>
      </div>
      <div class="content">
        <div class="btn-group">
          <div :class="{ active: active == 1 }" class="btn" @click="active = 1; getRecruit();">社招</div>
          <div :class="{ active: active == 2 }" class="btn" @click="active = 2; getRecruit();">校招</div>
        </div>
        <el-row class="card-group" :gutter="20">
          <el-col v-for="item in recruit" :key="item._id" :md="12" :sm="24">
            <div @click="$router.push('/about/recruit/'+item._id)">
              <el-card shadow="hover">
                <div class="title">
                  <img src="@/assets/icon.png" alt="">
                  <span>{{ item.name }}</span>
                </div>
                <div class="label">
                  <span v-if="item.bm">{{ item.bm }}</span>
                  <span v-if="item.cs">{{ item.cs }}</span>
                  <span v-if="item.xz">{{ item.xz }}</span>
                  <span v-if="item.rs">{{ item.rs }}</span>
                  <span v-if="item.sj">{{ item.sj }}</span>
                </div>
                <div class="description">{{ item.zz.join('  ') }}</div>
              </el-card>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <page-footer />
  </div>
</template>

<script>
  import PageHeader from "@/components/header.vue";
  import PageFooter from "@/components/footer.vue";

  export default {
    name: 'recruit',
    props: {
      background: {}
    },
    components: {
      PageHeader,
      PageFooter,
    },
    data() {
      return {
        lang: window.localStorage.getItem('lang'),
        active: 1,
        roll: false,
        recruit: []
      }
    },
    created() {
      this.getRecruit()
      this.$api.get('/client/access-statistics/pub/index', {
        code: '5-about',
        name: '关于GaNext',
        pageCode: 'recruit',
        title: '加入我们',
        url: this.$route.fullPath
      })
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll)
    },
    methods: {
      handleScroll() {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        this.roll = scrollTop > 70 ? true : false
      },
      getRecruit() {
        this.$api.get('/client/recruit/pub/getList', {
          classification: this.active
        }, res => {
          this.recruit = res.data
        }, err => {
          console.log(err)
        })
      },
    }
  }
</script>

<style scoped>
  .recruit .bg {
    width: 100%;
    font-size: 0;
    position: relative;
  }

  .recruit .bg img {
    width: 100%;
  }

  .recruit .bg .title {
    font-size: 30px;
    color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 20px;
    background-color: rgb(1, 51, 120, .5);
  }

  .content {
    width: 90%;
    margin: 0 auto;
    padding: 20px 0;
  }

  .content .btn-group {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 50px 0;
  }

  .content .btn-group .btn {
    background-color: #f2f5f7;
    color: #013378;
    border-radius: 4px;
    padding: 5px 15px;
    font-size: 16px;
    cursor: pointer;
  }

  .content .btn-group .btn:not(:last-child) {
    margin-right: 10px;
  }

  .content .btn-group .btn:hover {
    background-color: #013378;
    color: #fff;
  }

  .content .btn-group .btn.active {
    background-color: #013378;
    color: #fff;
    box-shadow: 0px 2px 6px 0px rgb(1, 51, 120, 0.3);
  }

  .content .card-group .el-col {
    cursor: pointer;
    margin-bottom: 20px;
  }

  .content .card-group .el-card {
    border-radius: 0;
    height: 150px;
  }

  .content .card-group .title {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  .content .card-group .title img {
    height: 8px;
    margin-right: 10px;
  }

  .content .card-group .title span {
    font-size: 20px;
  }

  .content .card-group .label {
    margin-bottom: 8px;
    color: #848999;
  }

  .content .card-group .label span:not(:last-child) {
    border-right: 1px solid #848999;
    padding-right: 10px;
    margin-right: 10px;
  }

  .content .card-group .description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.6;
    color: #525A6F;
  }
</style>
