<template>
  <div class="home">
    <page-header :roll="roll" />
    <!-- 轮播图 -->
    <el-carousel trigger="click" arrow="always" class="banner" height="calc(100vw / 3)">
      <el-carousel-item v-for="(item, i) in (info.banner || [])" :key="i">
        <el-image :src="item.image" fit="cover" style="height: 100%;width: 100%;" @click="openPage(item.link)" />
      </el-carousel-item>
    </el-carousel>
    <div class="container hidden-sm-and-down">
      <!-- 公司介绍 -->
      <div class="introduce">
        <div class="title">
          <div class="text">{{ info.introduceTitle }}</div>
          <div class="sub">{{ info.introduceSub }}</div>
        </div>
        <div class="content">
          <div class="video">
            <video controls="controls" :src="info.video"></video>
          </div>
          <div class="info">
            <!-- <div class="name"><span>◎</span>珠海镓未来科技有限公司</div> -->
            <div class="detail">{{ info.introduceDesc }}</div>
          </div>
        </div>
      </div>
      <!-- 应用场景 -->
      <div class="scene">
        <div class="title">
          <div class="text">{{ info.sceneTitle }}</div>
          <div class="sub">{{ info.sceneSub }}</div>
        </div>
        <el-carousel height="calc(60vw / 16 * 9 + 100px)" trigger="click" arrow="always" indicator-position="none" :autoplay="false" class="content">
          <el-carousel-item v-for="(item, i) in (info.products || [])" :key="i">
            <div class="item" @click="openPage(item.link)">
              <el-image :src="item.image" fit="cover" />
              <div class="group">
                <div class="text">{{ item.title }}</div>
                <div class="desc">{{ item.desc }}</div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 产品方案 -->
      <div class="programme">
        <div class="title">
          <div class="text">{{ info.programmeTitle }}</div>
          <div class="sub">{{ info.programmeSub }}</div>
        </div>
        <div class="content">
          <div v-for="(item, i) in (info.industries || [])" :key="i" class="item">
            <el-image :src="item.image" fit="cover" />
            <div class="info">
              <div class="text">{{ item.title }}</div>
              <div class="horizontal-bar"></div>
              <div class="desc">{{ item.desc }}</div>
              <div class="btn" @click="openPage(item.link)">了解详情</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container mobile hidden-md-and-up">
      <!-- 公司介绍 -->
      <div class="introduce">
        <div class="title">
          <div class="text">{{ info.introduceTitle }}</div>
          <div class="sub">{{ info.introduceSub }}</div>
        </div>
        <div class="content">
          <div class="video">
            <video controls="controls" :src="info.video"></video>
          </div>
          <div class="info">
            <!-- <div class="name"><span>◎</span>珠海镓未来科技有限公司</div> -->
            <div class="detail">{{ info.introduceDesc }}</div>
          </div>
        </div>
      </div>
      <!-- 应用场景 -->
      <div class="scene">
        <div class="title">
          <div class="text">{{ info.sceneTitle }}</div>
          <div class="sub">{{ info.sceneSub }}</div>
        </div>
        <el-carousel height="calc(60vw / 16 * 9 + 100px)" trigger="click" arrow="always" indicator-position="none" :autoplay="false" class="content">
          <el-carousel-item v-for="(item, i) in (info.products || [])" :key="i">
            <div class="item" @click="openPage(item.link)">
              <el-image :src="item.image" fit="cover" />
              <div class="group">
                <div class="text">{{ item.title }}</div>
                <div class="desc">{{ item.desc }}</div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 产品方案 -->
      <div class="programme">
        <div class="title">
          <div class="text">{{ info.programmeTitle }}</div>
          <div class="sub">{{ info.programmeSub }}</div>
        </div>
        <div class="content">
          <div v-for="(item, i) in (info.industries || [])" :key="i" class="item">
            <el-image :src="item.image" fit="cover" />
            <div class="info">
              <div class="text">{{ item.title }}</div>
              <div class="horizontal-bar"></div>
              <div class="desc">{{ item.desc }}</div>
              <div class="btn" @click="openPage(item.link)">了解详情</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <page-footer />
  </div>
</template>

<script>
import PageHeader from "@/components/header.vue";
import PageFooter from "@/components/footer.vue";

export default {
  name: "Home",
  components: {
    PageHeader,
    PageFooter,
  },
  data() {
    return {
      timer: null,
      roll: false,
      info: {}
    };
  },
  created() {
    this.getInfo()
    this.$api.get('/client/access-statistics/pub/index', {
      code: '1-home',
      name: '首页',
      pageCode: '',
      title: '',
      url: this.$route.fullPath
    })
  },
  methods: {
    getInfo() {
      this.$api.get('/client/home/pub/getInfo', {}, res => {
        this.info = res.data[0]
        this.info && this.info.banner && this.info.banner.sort((a, b) => {
          if (a.sort < b.sort) return -1
          else if (a.sort > b.sort) return 1
          else if (a.sort == b.sort) return 0
        })
      }, err => {
        console.log(err)
      })
    },
    openPage(url) {
      if(!url) return
      window.location.href = url
    }
  },
};
</script>

<style scoped>
.home {
  margin-top: 0;
}

.home .container > div:nth-child(2n),
.home .container.mobile > div:nth-child(4n) {
  background-color: #fafafa;
}

.home .banner .img-bg {
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, .9);
}
.home .banner img {
  width: 100%;
}

.home .introduce {
  overflow: auto;
  padding: 100px 0;
}
.home .introduce .title {
  text-align: center;
  margin-bottom: 60px;
}
.home .introduce .title .text {
  font-size: 35px;
  font-weight: 700;
  color: #333;
}
.home .introduce .title .sub {
  font-size: 16px;
  color: #333;
  opacity: .6;
  letter-spacing: 4px;
  word-spacing: 4px;
  margin-top: 10px;
}
.home .introduce .content {
  display: flex;
}
.home .introduce .content .video {
  width: 50%;
  flex-shrink: 0;
}
.home .introduce .content .video video {
  width: 100%;
}
.home .introduce .content .info {
  padding: 0 40px;
}
.home .introduce .content .info .name {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;
  color: #000;
  display: flex;
  align-items: center;
}
.home .introduce .content .info .name span {
  font-size: 16px;
  font-weight: bold;
  margin-right: 10px;
  line-height: 1;
}
.home .introduce .content .info .detail {
  line-height: 2;
  font-size: 14px;
  font-weight: 100;
  color: #333;
  opacity: .8;
  white-space: pre-wrap;
}

.home .scene {
  overflow: auto;
  padding: 100px 0;
}
.home .scene >>> .el-carousel__arrow {
  top: calc(50% - 50px);
}
.home .scene .title {
  text-align: center;
  margin-bottom: 60px;
}
.home .scene .title .text {
  font-size: 35px;
  font-weight: 700;
  color: #333;
}
.home .scene .title .sub {
  font-size: 16px;
  color: #333;
  opacity: .6;
  letter-spacing: 4px;
  word-spacing: 4px;
  margin-top: 10px;
}
.home .scene .content {
  width: 60vw;
  margin: 0 auto;
}
.home .scene .content .item {
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
.home .scene .content .item .el-image {
  width: 60vw;
  height: calc(60vw / 16 * 9);
  flex-shrink: 0;
}
.home .scene .content .item .group {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.home .scene .content .item .group .text {
  font-size: 20px;
  font-weight: 400;
  color: #000;
}
.home .scene .content .item .group .desc {
  font-size: 14px;
  font-weight: 100;
  color: #333;
  opacity: .6;
  margin-top: 6px;
}

.home .programme {
  overflow: auto;
  padding: 100px 0;
}
.home .programme .title {
  text-align: center;
  margin-bottom: 60px;
}
.home .programme .title .text {
  font-size: 35px;
  font-weight: 700;
  color: #333;
}
.home .programme .title .sub {
  font-size: 16px;
  color: #333;
  opacity: .6;
  letter-spacing: 4px;
  word-spacing: 4px;
  margin-top: 10px;
}
.home .programme .content {
  width: 60vw;
  margin: 0 auto;
}
.home .programme .content .item {
  display: flex;
  background-color: #fff;
}
.home .programme .content .item:not(:last-child) {
  margin-bottom: 60px;
}
.home .programme .content .item:nth-child(2n) {
  flex-direction: row-reverse;
}
.home .programme .content .item .el-image {
  width: calc(60vw / 3);
  height: calc(60vw / 3);
  flex-shrink: 0;
  margin-left: 0;
  margin-right: 40px;
}
.home .programme .content .item:nth-child(2n) .el-image {
  margin-left: 40px;
  margin-right: 0;
}
.home .programme .content .item .info {
  flex-grow: 1;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.home .programme .content .item .info .text {
  font-size: 20px;
  font-weight: 400;
  color: #000;
}
.home .programme .content .item .info .horizontal-bar {
  height: 4px;
  width: 50px;
  background-color: #1a335f;
  margin-top: 40px;
  margin-bottom: 15px;
}
.home .programme .content .item .info .desc {
  font-size: 14px;
  font-weight: 100;
  color: #333;
  opacity: .8;
  margin-bottom: 40px;
}
.home .programme .content .item .info .btn {
  background-color: #1a335f;
  color: #fff;
  border-radius: 35px;
  font-size: 14px;
  padding: 2px 15px;
  cursor: pointer;
}

.home .container.mobile .introduce,
.home .container.mobile .scene,
.home .container.mobile .programme {
  padding: 30px 0;
}
.home .container.mobile .introduce .title,
.home .container.mobile .scene .title,
.home .container.mobile .programme .title {
  margin-bottom: 20px;
}
.home .container.mobile .introduce .title .text,
.home .container.mobile .scene .title .text,
.home .container.mobile .programme .title .text {
  font-size: 24px;
}
.home .container.mobile .introduce .title .sub,
.home .container.mobile .scene .title .sub,
.home .container.mobile .programme .title .sub {
  font-size: 12px;
  margin-top: 5px;
}

.home .container.mobile .introduce .content {
  flex-direction: column;
}
.home .container.mobile .introduce .content .video {
  width: calc(100% - 40px);
  margin: 0 auto;
}
.home .container.mobile .introduce .content .info {
  padding: 0 20px;
  margin-top: 10px;
}

.home .container.mobile .scene .content {
  width: calc(100vw - 40px);
}
.home .container.mobile .scene .content >>> .el-carousel__container {
  height: calc((100vw - 40px) / 16 * 9 + 80px) !important;
}
.home .container.mobile .scene .content >>> .el-carousel__arrow {
  top: calc(50% - 40px);
}
.home .container.mobile .scene .content .item .el-image {
  width: calc(100vw - 40px);
  height: calc((100vw - 40px) / 16 * 9);
}
.home .container.mobile .scene .content .item .group .text {
  font-size: 16px;
}
.home .container.mobile .scene .content .item .group .desc {
  font-size: 12px;
}

.home .container.mobile .programme .content {
  width: calc(100vw - 40px);
}
.home .container.mobile .programme .content .item:not(:last-child) {
  margin-bottom: 20px;
}
.home .container.mobile .programme .content .item .el-image {
  margin-right: 10px;
  width: calc((100vw - 40px) / 3.5);
  height: calc((100vw - 40px) / 3.5);
}
.home .container.mobile .programme .content .item:nth-child(2n) .el-image {
  margin-left: 10px;
  margin-right: 0;
}
.home .container.mobile .programme .content .item .info {
  padding: 0 0 0 10px;
}
.home .container.mobile .programme .content .item:nth-child(2n) .info {
  padding: 0 10px 0 0;
}
.home .container.mobile .programme .content .item .info .text {
  font-size: 18px;
}
.home .container.mobile .programme .content .item .info .horizontal-bar {
  margin-top: 10px;
  margin-bottom: 10px;
}
.home .container.mobile .programme .content .item .info .desc {
  margin-bottom: 15px;
}
</style>