import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'
import ApplicationClassification from '../views/application-classification.vue'
import Application from '../views/application.vue'
import ProductClassification from '../views/product-classification.vue'
import Product from '../views/product.vue'
import TechnologyClassification from '../views/technology-classification.vue'
import Technology from '../views/technology.vue'
import CompanyIntroduce from '../views/company-introduce.vue'
import Recruit from '../views/recruit.vue'
import RecruitDetail from '../views/recruit-detail.vue'
import Distributor from '../views/distributor.vue'
import Contact from '../views/contact.vue'
import InvestorRelatioin from '../views/investor-relatioin.vue'
import NewsClassification from '../views/news-classification.vue'
import News from '../views/news.vue'
// import NotPage from '../views/not-page.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/application/classification',
    name: 'applicationClassification',
    component: ApplicationClassification
  },
  {
    path: '/application/detail/:id',
    name: 'application',
    component: Application
  },
  {
    path: '/product/classification',
    name: 'productClassification',
    component: ProductClassification
  },
  {
    path: '/product',
    name: 'product',
    component: Product
  },
  {
    path: '/technology/classification',
    name: 'technologyClassification',
    component: TechnologyClassification
  },
  {
    path: '/technology/detail/:id',
    name: 'technology',
    component: Technology
  },
  {
    path: '/about/company-introduce',
    name: 'companyIntroduce',
    component: CompanyIntroduce
  },
  {
    path: '/about/recruit',
    name: 'recruit',
    component: Recruit
  },
  {
    path: '/about/recruit/:id',
    name: 'recruitDetail',
    component: RecruitDetail
  },
  {
    path: '/about/distributor',
    name: 'distributor',
    component: Distributor
  },
  {
    path: '/about/contact',
    name: 'contact',
    component: Contact
  },
  {
    path: '/about/investor-relatioin',
    name: 'investorRelatioin',
    component: InvestorRelatioin
  },
  {
    path: '/news/classification',
    name: 'newsClassification',
    component: NewsClassification
  },
  {
    path: '/news/detail/:id',
    name: 'news',
    component: News
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  const lang = window.localStorage.getItem('lang')
  if(!lang) window.localStorage.setItem('lang', 'zh-CN')

  next()
})

export default router
