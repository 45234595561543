<template>
  <div class="product-classification">
    <page-header :roll="roll" />
    <div class="container">
      <div class="bg">
        <img class="hidden-sm-and-down" src="@/assets/bg1.jpg" alt="">
        <el-image class="hidden-md-and-up" :src="require('@/assets/bg1.jpg')" fit="cover" style="width: 100%; height: 200px;" />
        <div class="title">{{ $lang.product[lang] }}</div>
      </div>
      <div class="content">
        <div class="btn-group">
          <div
            v-for="(item, i) in productClassification"
            :key="item._id"
            :class="{'active': active == i}"
            class="btn"
            @click="switchMenu(i)"
          >{{ item.name }}</div>
        </div>
        <el-row v-if="active != -1" class="card-group" :gutter="20">
          <el-col
            v-for="item in productClassification[active].children"
            :key="item._id"
            :xs="24"
            :sm="12"
            :md="12"
            :lg="8"
          >
            <div @click="$router.push('/product/detail/'+productClassification[active]._id+'?id='+item._id)">
              <el-card shadow="hover">
                <div class="top">
                  <div class="name">{{ item.name }}</div>
                </div>
                <div class="bottom">
                  <div class="avatar">
                    <img :src="item.cover" alt="">
                  </div>
                  <div class="sketch">{{ item.sketch }}</div>
                </div>
              </el-card>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <page-footer />
  </div>
</template>

<script>
import PageHeader from "@/components/header.vue";
import PageFooter from "@/components/footer.vue";

export default {
  name: 'productClassification',
  components: {
    PageHeader,
    PageFooter,
  },
  data() {
    return {
      lang: window.localStorage.getItem('lang'),
      active: -1,
      roll: false,
      productClassification: [],
    }
  },
  watch: {
    '$route.query.id'() {
      this.active = this.productClassification.findIndex(el => el._id == this.$route.query.id)
    }
  },
  created() {
    this.getClassification()
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      this.roll = scrollTop > 70 ? true : false
    },
    switchMenu(i) {
      if(this.active == i) return
      this.$router.push('/product/classification?id=' + this.productClassification[i]._id)
    },
    getClassification() {
      this.$api.get('/client/product/pub/getMenu', {}, res => {
        this.productClassification = res.data
        if (this.productClassification.length > 0 && this.active == -1) this.active = 0
        if (this.$route.query.id) this.active = this.productClassification.findIndex(el => el._id == this.$route.query.id)
      }, err => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>
.product-classification .bg {
  width: 100%;
  font-size: 0;
  position: relative;
}

.product-classification .bg img {
  width: 100%;
}
.product-classification .bg .title {
  font-size: 30px;
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 20px;
  background-color: rgb(1, 51, 120, .5);
}

.content {
  width: 90%;
  margin: 0 auto;
}

.content .btn-group {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 50px 0;
  flex-wrap: wrap;
}

.content .btn-group .btn {
  background-color: #F2F5F7;
  color: #013378;
  border-radius: 4px;
  padding: 5px 15px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
}

.content .btn-group .btn:not(:last-child) {
  margin-right: 10px;
}

.content .btn-group .btn:hover {
  background-color: #013378;
  color: #fff;
}

.content .btn-group .btn.active {
  background-color: #013378;
  color: #fff;
  box-shadow: 0px 2px 6px 0px rgb(1, 51, 120, .3);
}

.content .card-group {
  margin-bottom: 30px;
}

.content .card-group .el-col {
  cursor: pointer;
  margin-bottom: 20px;
}

.content .card-group .el-card {
  border-radius: 0;
}

.content .card-group .el-card .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.content .card-group .el-card .top .name {
  font-size: 18px;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 10px;
}

.content .card-group .el-card .top .date {
  font-size: 14px;
  color: #999;
  flex-shrink: 0;
}

.content .card-group .el-card .bottom {
  display: flex;
  align-items: center;
}

.content .card-group .el-card .bottom .avatar {
  font-size: 0;
  width: 30%;
  height: 100px;
  margin-right: 15px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.content .card-group .el-card .bottom .sketch {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.content .el-pagination {
  text-align: center;
  margin-bottom: 30px;
}

.content >>> .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #013378;
  color: #fff !important;
}

.content >>> .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #013378;
}
</style>