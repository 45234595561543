<template>
  <div class="distributor">
    <page-header :roll="roll" />
    <div class="container">
      <div class="bg">
        <img class="hidden-sm-and-down" :src="background.distributor || ''" alt="">
        <el-image class="hidden-md-and-up" :src="background.distributor || ''" fit="cover" style="width: 100%; height: 200px;" />
        <div class="title">分销商</div>
      </div>
      <div class="content">
        <el-row class="card-group" :gutter="20">
          <el-col
            v-for="item in distributor"
            :key="item._id"
            :xs="24"
            :sm="12"
            :md="12"
            :lg="8"
          >
            <div @click="openPage(item.link)">
              <el-card shadow="hover">
                <div class="title">{{ item.name }}</div>
                <div class="text">
                  <i class="el-icon-position"></i>
                  <span>{{ item.address }}</span>
                </div>
                <div class="text">
                  <i class="el-icon-message"></i>
                  <span>{{ item.email }}</span>
                </div>
                <div class="text">
                  <i class="el-icon-phone-outline"></i>
                  <span>{{ item.phone }}</span>
                </div>
              </el-card>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <page-footer />
  </div>
</template>

<script>
import PageHeader from "@/components/header.vue";
import PageFooter from "@/components/footer.vue";

export default {
  name: 'distributor',
  props: {
    background: {}
  },
  components: {
    PageHeader,
    PageFooter,
  },
  data() {
    return {
      lang: window.localStorage.getItem('lang'),
      roll: false,
      distributor: []
    }
  },
  created() {
    this.getDistributor()
    this.$api.get('/client/access-statistics/pub/index', {
      code: '5-about',
      name: '关于GaNext',
      pageCode: 'distributor',
      title: '分销商',
      url: this.$route.fullPath
    })
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    openPage(url) {
      if(!url) return
      window.open(url)
    },
    handleScroll() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      this.roll = scrollTop > 70 ? true : false
    },
    getDistributor() {
      this.$api.get('/client/distributor/pub/getList', {}, res => {
        this.distributor = res.data
      }, err => {
        console.log(err)
      })
    },
  }
}
</script>

<style scoped>
.distributor .bg {
  width: 100%;
  font-size: 0;
  position: relative;
}

.distributor .bg img {
  width: 100%;
}
.distributor .bg .title {
  font-size: 30px;
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 20px;
  background-color: rgb(1, 51, 120, .5);
}

.content {
  width: 90%;
  margin: 0 auto;
  padding: 20px 0;
}

.content .card-group .el-col {
  cursor: pointer;
  margin-bottom: 20px;
}

.content .card-group .el-card {
  border-radius: 0;
}

.content .card-group .title {
  font-size: 20px;
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.content .card-group .text i {
  color: #EB1302;
  margin-right: 8px;
}

.content .card-group .text:not(:last-child) {
  margin-bottom: 12px;
}
</style>