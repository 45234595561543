<template>
  <div id="app">
    <router-view class="page-body" :background="background" />
  </div>
</template>

<script>

export default {
  name: 'app',
  data() {
    return {
      background: ''
    }
  },
  created() {
    this.$api.get('/client/background/pub/getInfo', {}, res => {
      this.background = res.data[0]
    }, err => {
      console.log(err)
    })
  }
}
</script>

<style>
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  /* user-select: none; */
}

html,
body {
  width: 100%;
  height: 100%;
  background-color: #081834;
}

body {
  font-size: 14px;
  position: relative;
}

img {
  border: 0px;
  max-width: 100%;
}

ul,
li {
  list-style-type: none;
}

#app {
  font-family: "思源黑体", Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,\\5FAE\8F6F\96C5\9ED1,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.page-body {
  background-color: #fff;
  padding-top: 70px;
}
</style>
