<template>
  <div class="investor-relatioin">
    <page-header :roll="roll" />
    <div class="container">
      <div class="bg">
        <img class="hidden-sm-and-down" :src="background.investorRelatioin || ''" alt="">
        <el-image class="hidden-md-and-up" :src="background.investorRelatioin || ''" fit="cover" style="width: 100%; height: 200px;" />
        <div class="title">投资者关系</div>
      </div>
      <div class="content">
        <div style="white-space: pre-wrap;" v-html="info.content"></div>
      </div>
    </div>
    <page-footer />
  </div>
</template>

<script>
import PageHeader from "@/components/header.vue";
import PageFooter from "@/components/footer.vue";

export default {
  name: 'investorRelatioin',
  props: {
    background: {}
  },
  components: {
    PageHeader,
    PageFooter,
  },
  data() {
    return {
      lang: window.localStorage.getItem('lang'),
      roll: false,
      info: {
        content: ''
      }
    }
  },
  created() {
    this.getInfo()
    this.$api.get('/client/access-statistics/pub/index', {
      code: '5-about',
      name: '关于GaNext',
      pageCode: 'investor-relatioin',
      title: '投资者关系',
      url: this.$route.fullPath
    })
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      this.roll = scrollTop > 70 ? true : false
    },
    getInfo() {
      this.$api.get('/client/investor-relatioin/pub/getInfo', {}, res => {
        let data = res.data[0]
        this.info.content = data.content
      }, err => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>
.investor-relatioin .bg {
  width: 100%;
  font-size: 0;
  position: relative;
}

.investor-relatioin .bg img {
  width: 100%;
}
.investor-relatioin .bg .title {
  font-size: 30px;
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 20px;
  background-color: rgb(1, 51, 120, .5);
}

.content {
  width: 90%;
  margin: 0 auto;
  padding: 30px 0;
}
</style>