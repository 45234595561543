<template>
  <div class="recruit-detail">
    <page-header :roll="roll" />
    <div class="container">
      <div class="bg">
        <img class="hidden-sm-and-down" src="@/assets/bg1.jpg" alt="">
        <el-image class="hidden-md-and-up" :src="require('@/assets/bg1.jpg')" fit="cover" style="width: 100%; height: 200px;" />
        <div class="text">
          <div class="title">{{ recruit.name }}</div>
          <div class="label">
            <span v-if="recruit.bm">{{ recruit.bm }}</span>
            <span v-if="recruit.cs">{{ recruit.cs }}</span>
            <span v-if="recruit.xz">{{ recruit.xz }}</span>
            <span v-if="recruit.rs">{{ recruit.rs }}</span>
            <span v-if="recruit.sj">{{ recruit.sj }}</span>
          </div>
        </div>
      </div>
      <div class="content">
        <div v-if="recruit.zz && recruit.zz.length > 0" class="item">
          <div class="title">职责描述</div>
          <div class="list">
            <p v-for="(item, i) in recruit.zz" :key="i">{{ item }}</p>
          </div>
        </div>
        <div v-if="recruit.yq && recruit.yq.length > 0" class="item">
          <div class="title">任职要求</div>
          <div class="list">
            <p v-for="(item, i) in recruit.yq" :key="i">{{ item }}</p>
          </div>
        </div>
        <div v-if="recruit.jn && recruit.jn.length > 0" class="item">
          <div class="title">知识与技能</div>
          <div class="list">
            <p v-for="(item, i) in recruit.jn" :key="i">{{ item }}</p>
          </div>
        </div>
        <div v-if="recruit.sz && recruit.sz.length > 0" class="item">
          <div class="title">胜任力素质要求</div>
          <div class="list">
            <p v-for="(item, i) in recruit.sz" :key="i">{{ item }}</p>
          </div>
        </div>
        <div v-if="recruit.fl && recruit.fl.length > 0" class="item">
          <div class="title">公司福利</div>
          <div class="list">
            <p v-for="(item, i) in recruit.fl" :key="i">{{ item }}</p>
          </div>
        </div>
        <div v-if="recruit.lx" class="item">
          <div class="title">联系方式</div>
          <div class="list">
            <p v-for="(item, i) in recruit.lx" :key="i">{{ item }}</p>
          </div>
        </div>
        <el-button v-if="recruit.name" type="primary" @click="showResume = true">投递简历</el-button>
      </div>
    </div>
    <page-footer />

    <el-dialog title="投递简历" :visible.sync="showResume" width="400px" :close-on-click-modal="false" :close-on-press-escape="false" :destroy-on-close="true">
      <iframe :src="('/admin/#/pages/login/resume?recruit='+recruit.name+'&classification='+(recruit.classification==1?'sz':'xz'))" width="100%" height="270px" frameborder="0"></iframe>
    </el-dialog>
  </div>
</template>

<script>
import PageHeader from "@/components/header.vue";
import PageFooter from "@/components/footer.vue";

window.addEventListener('message', function() {
  window.closeDialog()
})

export default {
  name: 'recruitDetail',
  components: {
    PageHeader,
    PageFooter,
  },
  data() {
    return {
      lang: window.localStorage.getItem('lang'),
      roll: false,
      recruit: {},
      showResume: false,
    }
  },
  created() {
    this.getRecruit()
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    window.closeDialog = this.closeDialog
  },
  methods: {
    handleScroll() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      this.roll = scrollTop > 70 ? true : false
    },
    getRecruit() {
      this.$api.get('/client/recruit/pub/getRecruitById', {
        id: this.$route.params.id
      }, res => {
        let data = res.data[0]
        this.recruit = data || {}
        this.$api.get('/client/access-statistics/pub/index', {
          code: '5-about',
          name: '关于GaNext',
          pageCode: 'recruit-detail-' + this.$route.params.id,
          title: '职位 - ' + this.recruit.name,
          url: this.$route.fullPath
        })
      }, err => {
        console.log(err)
      })
    },
    closeDialog() {
      this.showResume = false
    }
  }
}
</script>

<style scoped>
.recruit-detail .bg {
  width: 100%;
  font-size: 0;
  position: relative;
}

.recruit-detail .bg img {
  width: 100%;
}
.recruit-detail .bg .text {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 20px;
  background-color: rgb(1, 51, 120, .5);
}
.recruit-detail .bg .title {
  font-size: 24px;
  color: #fff;
}
.recruit-detail .bg .label {
  margin-top: 5px;
  font-size: 16px;
  color: #e7e7e7;
}
.recruit-detail .bg .label span:not(:last-child) {
  border-right: 1px solid #e7e7e7;
  padding-right: 10px;
  margin-right: 10px;
}

.content {
  width: 90%;
  margin: 0 auto;
  padding: 50px 0;
}

.content .item:not(:last-child) {
  margin-bottom: 40px;
}

.content .item .title {
  font-size: 18px;
  border-left: 3px solid #EB1302;
  padding-left: 10px;
  line-height: 1;
}

.content .item .list {
  color: #525A6F;
  margin-top: 15px;
  line-height: 2;
}

* >>> .el-dialog__body {
  padding: 0 !important;
}
</style>