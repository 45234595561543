<template>
  <div class="header" :class="{'roll': roll}">
    <div class="content hidden-sm-and-down">
      <img class="logo" src="@/assets/logo.png" @click="openPage('/')" />
      <img class="logo-white" src="@/assets/logo-white.png" @click="openPage('/')" />
      <div class="right">
        <div class="menu">
          <div class="item" :class="{ active: $route.name == 'home' }" @click="openPage('/')">
            {{ $lang.home[lang] }}
          </div>
          <el-dropdown class="item" :class="{ active: $route.path.indexOf('/application') == 0 }" @command="openPage">
            <span>
              {{ $lang.application[lang] }}
              <i class="el-icon-arrow-down" style="font-size: 14px;"></i>
            </span>
            <el-dropdown-menu slot="dropdown" style="min-width: 150px;">
              <el-dropdown-item v-for="item in applicationClassification" :key="item._id" :command="'/application/classification?id='+item._id">
                {{ item.name }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div class="item" :class="{ active: $route.name == 'product' }" @click="openPage('/product')">
            {{ $lang.product[lang] }}
          </div>
          <el-dropdown class="item" :class="{ active: $route.path.indexOf('/technology') == 0 }" @command="openPage">
            <span>
              {{ $lang.technology[lang] }}
              <i class="el-icon-arrow-down" style="font-size: 14px;"></i>
            </span>
            <el-dropdown-menu slot="dropdown" style="min-width: 150px;">
              <el-dropdown-item v-for="item in technologyClassification" :key="item._id" :command="'/technology/classification?id='+item._id">
                {{ item.name }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown class="item" :class="{ active: $route.path.indexOf('/about') == 0 }" @command="openPage">
            <span>
              {{ $lang.about[lang] }}
              <i class="el-icon-arrow-down" style="font-size: 14px;"></i>
            </span>
            <el-dropdown-menu slot="dropdown" style="min-width: 150px;">
              <el-dropdown-item command="/about/company-introduce">公司介绍</el-dropdown-item>
              <el-dropdown-item command="/about/recruit">加入我们</el-dropdown-item>
              <el-dropdown-item command="/about/distributor">分销商</el-dropdown-item>
              <el-dropdown-item command="/about/investor-relatioin">投资者关系</el-dropdown-item>
              <el-dropdown-item command="/about/contact">联系我们</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown class="item" :class="{ active: $route.path.indexOf('/news') == 0 }" @command="openPage">
            <span>
              {{ $lang.news[lang] }}
              <i class="el-icon-arrow-down" style="font-size: 14px;"></i>
            </span>
            <el-dropdown-menu slot="dropdown" style="min-width: 150px;">
              <el-dropdown-item v-for="item in newsClassification" :key="item._id" :command="'/news/classification?id='+item._id">
                {{ item.name }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <!-- <div class="search-icon">
          <i class="el-icon-search"></i>
        </div> -->
        <!-- <el-dropdown trigger="click" @command="switchLang">
          <div class="lang">
            {{ langName }}<i class="el-icon-arrow-down el-icon--right"></i>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item, i) in langArr" :key="i" :command="item.value">
              {{ item.label }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
      </div>
    </div>
    <div class="content mobile hidden-md-and-up">
      <img class="logo" src="@/assets/logo.png" @click="openPage('/')" />
      <img class="logo-white" src="@/assets/logo-white.png" @click="openPage('/')" />
      <div class="menu-btn" @click="showMenu = true">
        <i class="el-icon-menu"></i>
      </div>
      <el-drawer
        :visible.sync="showMenu"
        :with-header="false"
        :append-to-body="true"
        direction="ltr"
        size="80%">
        <el-menu :default-active="$route.fullPath" text-color="#2a529f" active-text-color="#EB1302" :router="true" @select="showMenu = false">
          <el-menu-item index="/">
            <i class="el-icon-s-home"></i>
            <span slot="title">{{ $lang.home[lang] }}</span>
          </el-menu-item>
          <el-submenu index="application">
            <template slot="title">
              <i class="el-icon-s-help"></i>
              <span>{{ $lang.application[lang] }}</span>
            </template>
            <el-menu-item v-for="item in applicationClassification" :key="item._id" :index="'/application/classification?id='+item._id">
              {{ item.name }}
            </el-menu-item>
          </el-submenu>
          <el-menu-item index="/product">
            <i class="el-icon-s-goods"></i>
            <span slot="title">{{ $lang.product[lang] }}</span>
          </el-menu-item>
          <el-submenu index="technology">
            <template slot="title">
              <i class="el-icon-s-opportunity"></i>
              <span>{{ $lang.technology[lang] }}</span>
            </template>
            <el-menu-item v-for="item in technologyClassification" :key="item._id" :index="'/technology/classification?id='+item._id">
              {{ item.name }}
            </el-menu-item>
          </el-submenu>
          <el-submenu index="about">
            <template slot="title">
              <i class="el-icon-s-flag"></i>
              <span>{{ $lang.about[lang] }}</span>
            </template>
            <el-menu-item index="/about/company-introduce">公司介绍</el-menu-item>
            <el-menu-item index="/about/recruit">加入我们</el-menu-item>
            <el-menu-item index="/about/distributor">分销商</el-menu-item>
            <el-menu-item index="/about/investor-relatioin">投资者关系</el-menu-item>
            <el-menu-item index="/about/contact">联系我们</el-menu-item>
          </el-submenu>
          <el-submenu index="news">
            <template slot="title">
              <i class="el-icon-s-opportunity"></i>
              <span>{{ $lang.news[lang] }}</span>
            </template>
            <el-menu-item v-for="item in newsClassification" :key="item._id" :index="'/news/classification?id='+item._id">
              {{ item.name }}
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-drawer>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    roll: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      lang: window.localStorage.getItem('lang'),
      langArr: [
        { value: 'zh-CN', label: '中' },
        { value: 'en-US', label: 'EN' },
      ],
      applicationClassification: [],
      technologyClassification: [],
      newsClassification: [],
      showMenu: false
    }
  },
  computed: {
    langName() {
      let lang = this.langArr.find(el => el.value == this.lang)
      return lang ? lang.label : ''
    },
  },
  methods: {
    switchLang(lang) {
      if(this.lang == lang) return
      window.localStorage.setItem('lang', lang)
      window.location.reload()
    },
    openPage(str) {
      this.$router.push(str)
    },
    getApplicationClassification() {
      this.$api.get('/client/application/pub/getClassification', {}, res => {
        this.applicationClassification = res.data
      }, err => {
        console.log(err)
      })
    },
    getTechnologyClassification() {
      this.$api.get('/client/technology/pub/getClassification', {}, res => {
        this.technologyClassification = res.data
      }, err => {
        console.log(err)
      })
    },
    getNewsClassification() {
      this.$api.get('/client/news/pub/getClassification', {}, res => {
        this.newsClassification = res.data
      }, err => {
        console.log(err)
      })
    }
  },
  created() {
    this.getApplicationClassification()
    this.getTechnologyClassification()
    this.getNewsClassification()
  }
}
</script>

<style scoped>
.el-menu-item i,
.el-submenu__title i {
  color: inherit;
}
.header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  width: 100%;
  /* background-color: rgba(0, 0, 0, .3); */
  /* transition: background-color .5s, box-shadow .5s; */
  background-color: #fff;
  box-shadow: 0 0 2px 0 #bbb;
}

.roll {
  background-color: #fff;
  box-shadow: 0 0 2px 0 #bbb;
}

.header .content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 96%;
  height: 70px;
  padding: 18px 12px;
  margin: 0 auto;
}

.header .content.mobile {
  height: 56px;
  padding: 12px;
}

.header .logo,
.header .logo-white {
  height: 100%;
  cursor: pointer;
}

.header .logo {
  /* display: none; */
  display: block;
}
.header .logo-white {
  /* display: block; */
  display: none;
}

.roll .logo {
  display: block;
}
.roll .logo-white {
  display: none;
}

.header .right {
  height: 100%;
  display: flex;
  align-items: center;
}

.header .menu-btn {
  font-size: 30px;
  /* color: #fff; */
  color: #2a529f;
}

.header .content.mobile .menu-btn {
  font-size: 24px;
}

.roll .menu-btn {
  color: #2a529f;
}

.header .menu {
  display: flex;
  align-items: center;
  margin-right: 35px;
}

.header .menu .item {
  font-size: 16px;
  /* color: #fff; */
  color: #2a529f;
  cursor: pointer;
  font-weight: 400;
}

.roll .menu .item {
  color: #2a529f;
}

.header .menu .item:not(:last-child) {
  margin-right: 25px;
}

.header .menu .item.active {
  font-weight: 600;
  /* color: #EB1302; */
}

.roll .menu .item.active {
  font-weight: 600;
  /* color: #EB1302; */
}

.header .lang {
  cursor: pointer;
  /* color: #fff; */
  color: #2a529f;
  font-size: 18px;
}

.roll .lang {
  color: #2a529f;
}

.header .lang i {
  font-size: 14px;
}

.header .search-icon {
  font-size: 0;
  /* color: #fff; */
  color: #2a529f;
  margin-right: 20px;
  cursor: pointer;
}

.header .search-icon i {
  font-size: 18px;
  font-weight: 600;
}

.roll .search-icon {
  color: #2a529f;
}
</style>